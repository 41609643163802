import { BofAAppMain } from '@/layout/components/index';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';

export const portfoliosChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.PORTFOLIO_FACTSHEET,
    path: ':indexUniqueIdentifier',
    components: {
      default: () => import('@/views/factsheets/PortfolioFactsheet.vue'),
      'analysis-step-toolbar': () => import('@/views/factsheets/FactsheetToolbar.vue'),
    },
    meta: {
      title: 'Portfolio Factsheet',
      isMainContentContainerFluid: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
    },
  },
  {
    name: RouteName.PORTFOLIO_FACTSHEET_PDF,
    path: ':indexUniqueIdentifier/pdf',
    component: () => import('@/views/factsheets/PortfolioFactsheetPdf.vue'),
    meta: {
      title: 'Portfolio Factsheet PDF',
      pdf: true,
    },
  },
];

export const PortfoliosRouter: RouteConfig[] = [
  {
    path: '/portfolio',
    components: {
      default: BofAAppMain,
    },
    children: portfoliosChildrenRouter,
  },
];
