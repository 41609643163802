<template>
  <div v-show="shouldBeShown">
    <template v-if="shouldUseWhitelabelCustomPdfFooterComponent">
      <WhitelabelPdfFooter />
    </template>
    <!-- do NOT use d-print-none because then repeating headers are broken :( -->
    <b-container
      v-else
      :fluid="isMainContentFluid ? true : null"
      :class="isMainContentFluid ? 'px-5' : ''"
    >
      <b-row :class="isPdf ? '' : 'mt-4 mb-3'">
        <b-col class="d-flex justify-content-end text-right">
          <small
            :class="isPdf ? 'py-3' : ''"
            :style="isMainContentFluid && !isPdf ? 'padding-right: 15px' : ''"
          >
            <span
              v-if="releaseInfo"
              class="text-muted"
            >
              <a
                v-if="releaseInfo.url"
                :href="releaseInfo.url"
              >
                {{ releaseInfo.name }}
              </a>
              <span v-else>
                {{ releaseInfo.name }}
              </span>
              |
            </span>
            <a
              href="/terms"
              target="_blank"
            >
              {{ translate({ path: 'APP_FOOTER.TERMS_CONDITIONS' }) }}
            </a>
            |
            <a
              href="/privacy"
              target="_blank"
            >
              {{ translate({ path: 'APP_FOOTER.PRIVACY_POLICY' }) }}
            </a>
            | &copy; {{ fullYear }}
            <span v-if="whitelabelClient">
              {{ whitelabelClient }} |
              <i18n-t
                keypath="APP_FOOTER.FAUX_WHITELABEL.POWERED_BY"
                tag="a"
                href="https://www.premialab.com"
                target="_blank"
              >
                {{ companyName }}
              </i18n-t>
            </span>
            <span v-else>
              {{ companyName }}
            </span>
          </small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script lang="ts">
import WhitelabelPdfFooter from './pdf/WhitelabelPdfFooter.vue';
import { SettingsModule } from '@/store/barrel';
import { useIsPdf } from '@/composables/usePdf';
import { computed, defineComponent } from 'vue';
import { RouteName } from '@/constants/RouteName';
import { useRouteRef } from '@/composables/useRouter';
import useEnv from '@/composables/useEnv';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import useUser from '@/composables/useUser';
import useTranslation from '@/composables/useTranslation';

function useReleaseInfo() {
  const { isProduction } = useEnv();
  const isPdf = useIsPdf();

  return computed(() => {
    if (isProduction.value) {
      return null;
    }

    if (isPdf.value) {
      return null;
    }

    // Only show version in core platform. We can't extract meaningful info from
    // whitelabel anyway. They only have the branch info.
    if (import.meta.env.VITE_CLIENT !== 'Premialab') {
      return null;
    }

    const name = import.meta.env.VITE_GH_REF_NAME;
    const type = import.meta.env.VITE_GH_REF_TYPE;

    // Require gh context injected in env
    if (name == null || type == null) {
      return null;
    }

    if (type === 'branch') {
      // Branch name `release/v4.xx`
      // Actually this case should never get triggered. We trigger CI via tag, not
      // via branch.
      if (name.startsWith('release/')) {
        const version = name.replace('release/', '');
        return {
          name: version,
          url: `https://premialab.atlassian.net/projects/WAA?contains=${version}&selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=released-unreleased`,
        };
      }

      // Can't extract the version here. So just show the generic branch name
      return {
        name,
        url: null,
      };
    }

    if (name.startsWith('uat-master')) {
      return {
        name: 'master',
        url: null,
      };
    }

    // version is `v4.xx.x`
    const version = name.split('-')[1];
    if (version == null) {
      return {
        name,
        url: null,
      };
    }

    // Only the minor version. Like v4.96.1 to v4.96
    const minor = version.replace(/\.\d+$/, '');
    return {
      name: version,
      url: `https://premialab.atlassian.net/projects/WAA?contains=${minor}&selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=released-unreleased`,
    };
  });
}

export default defineComponent({
  name: 'AppFooter',
  components: {
    WhitelabelPdfFooter,
  },
  setup() {
    const isPdf = useIsPdf();

    const fullYear = new Date().getFullYear();

    const isMainContentFluid = computed(() => route.value.meta?.isMainContentContainerFluid ?? false);

    const { isFauxWhitelabel } = useFeatureFlag();

    const { user } = useUser();
    const { translate } = useTranslation();

    const whitelabelClient = computed(() => {
      if (isFauxWhitelabel.value) {
        return user.value?.team.name;
      }
      return null;
    });

    const companyName = import.meta.env.VITE_CLIENT;

    /**
     * On whitelabel branches, set this boolean to true in order to
     * completely replace the PDF footer on each page
     */
    const shouldUseWhitelabelCustomPdfFooterComponent = computed(
      () => SettingsModule.shouldUseWhitelabelCustomPdfFooterComponent,
    );

    const { isBofAEnvironment } = useEnv();

    const route = useRouteRef();
    // Only show the footer if we are not on the login page or not in BofA Env.
    const shouldBeShown = computed(
      () => route.value.name && route.value.name !== RouteName.LOGIN && !isBofAEnvironment,
    );

    const releaseInfo = useReleaseInfo();

    return {
      fullYear,
      isMainContentFluid,
      companyName,
      shouldUseWhitelabelCustomPdfFooterComponent,
      isPdf,
      shouldBeShown,
      releaseInfo,
      whitelabelClient,
      translate,
    };
  },
});
</script>
