<template>
  <div
    v-if="isShowUserImpersonatingBanner"
    class="impersonation-banner"
  >
    Impersonating {{ email }}
  </div>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ImpersonationWarning',
  setup() {
    const { user, isShowUserImpersonatingBanner } = useUser();

    const email = computed(() => user.value?.email ?? 'N/A');

    return {
      isShowUserImpersonatingBanner,
      email,
    };
  },
});
</script>

<style>
.impersonation-banner {
  position: fixed;
  top: 0;
  left: 0;
  background: red;
  padding: 4px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  z-index: 99999999;
}
</style>
