import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { AppMain } from '@/layout/components/index';
import { notNull } from '@/utils/notnull';
import { RouteConfig } from 'vue-router';

export const ResourcesRouter: RouteConfig[] = [
  {
    name: RouteName.RESOURCES,
    path: '/resources',
    components: {
      default: AppMain,
    },
    redirect: {
      name: RouteName.KNOWLEDGE_BASE,
    },
    children: [
      {
        name: RouteName.KNOWLEDGE_BASE,
        path: 'knowledge-base',
        components: {
          default: () => import(`@/views/knowledge-base/KnowledgeBase.vue`),
          navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
        },
        meta: {
          title: 'Knowledge Base',
          bannerTitle: 'Knowledge Base',
          permission: [
            (perm: Set<UserPermission>) =>
              perm.has(UserPermission.API) || (perm.has(UserPermission.UNBLOCK) && perm.has(UserPermission.PLATFORM)),
          ],
        },
      },
      {
        name: RouteName.RESOURCES_API_CLIENT,
        path: 'api-docs',
        components: {
          default: () => import('@/views/resources/api-docs.vue'),
          navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
        },
        meta: {
          title: 'API Documentation',
          permission: [UserPermission.API],
        },
      },

      import.meta.env.VITE_CLIENT === 'Premialab'
        ? {
            name: RouteName.RESOURCES_API_PREMIALAB,
            path: 'api-developer',
            components: {
              default: () => import('@/views/resources/api-developer.vue'),
              navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
            },
            meta: {
              title: 'Developer API',
              permission: [UserPermission.ADMIN],
            },
          }
        : null,
      // For now, only add file download route for core platform only.
      // We will figure out how this should work on barclays/bofa later.
      // See WAA-5743
      import.meta.env.VITE_CLIENT === 'Premialab'
        ? {
            name: RouteName.RESOURCES_FILE_DOWNLOADS,
            path: 'downloads/plbpy',
            components: {
              default: () => import('@/views/resources/FileDownloads.vue'),
              navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
            },
            meta: {
              title: 'PlbPy',
              bannerTitle: 'Download PlbPy',
              permission: [UserPermission.API],
            },
          }
        : null,
      import.meta.env.VITE_CLIENT === 'Premialab'
        ? {
            path: 'downloads/files',
            redirect: 'downloads/plbpy',
          }
        : null,
      import.meta.env.VITE_CLIENT === 'Premialab'
        ? ({
            name: RouteName.RESOURCES_RISK_REPORT_DOWNLOADS,
            path: 'downloads/risk-reports',
            components: {
              default: () => import('@/views/resources/RiskReportDownloads.vue'),
              navbar: () => import('@/views/resources/ResourcesNavbar.vue'),
            },
            meta: {
              title: 'Risk Report Downloads',
              bannerTitle: 'Download Risk Reports (Last 30 Days)',
              permission: [
                (perm) =>
                  perm.has(UserPermission.API) ||
                  (perm.has(UserPermission.UNBLOCK) && perm.has(UserPermission.PLATFORM)),
              ],
            },
          } as RouteConfig)
        : null,
    ].filter(notNull),
  },
];
