import request from '@/api-v2/request';
import { IAdminUserItem } from './types/IAdminUserItem';
import { IAdminCreateUser } from './types/IAdminCreateUser';
import { IAdminUserDetail } from './types/IAdminUserDetail';
import { IResetPasswordResponse } from './types/IResetPasswordResponse';
import { IImpersonateResponse } from './types/IImpersonateResponse';
import { IUpdateUserRequest } from './types/IUpdateUserRequest';
import { IExportUserCSVResponse } from './types/IExportUserCSVResult';
import { IAdminTransferUserRequest } from './types/IAdminTransferUserRequest';

/**
 * Admin Api
 */
export const adminListUsers = (): Promise<IAdminUserItem[]> =>
  request({
    url: `/admin/users`,
    method: 'get',
  }).then((o) => o.data);

export const adminExportUsers = (): Promise<IExportUserCSVResponse> =>
  request({
    url: `/admin/users/csv`,
    method: 'post',
  }).then((o) => o.data);

export const adminCreateUser = (body: IAdminCreateUser): Promise<{ id: number }> =>
  request({
    url: `/admin/users`,
    method: 'post',
    data: body,
  }).then((o) => o.data);

export const adminGetUser = (id: string): Promise<IAdminUserDetail> =>
  request({
    url: `/admin/users/${id}`,
    method: 'get',
  }).then((o) => o.data);

export const adminUpdateUser = (id: number, user: IUpdateUserRequest): Promise<void> =>
  request({
    url: `/admin/users/${id}`,
    method: 'post',
    data: user,
  }).then((o) => o.data);

export const adminTransferUser = (id: number, body: IAdminTransferUserRequest): Promise<{ id: number }> =>
  request({
    url: `/admin/users/${id}/team`,
    method: 'post',
    data: body,
  }).then((o) => o.data);

export const adminTriggerBlockUser = (id: string): Promise<void> =>
  request({
    url: `/admin/users/${id}/trigger-block`,
    method: 'get',
  }).then((o) => o.data);

export const adminSendApproval = (id: string): Promise<void> =>
  request({
    url: `/admin/users/${id}/send-approval`,
    method: 'get',
  }).then((o) => o.data);

export const resetPasswordLink = (id: string): Promise<IResetPasswordResponse> =>
  request({
    url: `/admin/users/${id}/reset-link`,
    method: 'get',
  }).then((o) => o.data);

export const adminToggleMfa = (id: string): Promise<void> =>
  request({
    url: `/admin/users/${id}/toggle-mfa`,
    method: 'POST',
  }).then((o) => o.data);

export const getImpersonateToken = (id: string): Promise<IImpersonateResponse> =>
  request({
    url: `/admin/users/${id}/impersonate`,
    method: 'POST',
  }).then((o) => o.data);

export const adminDeleteUser = (id: string): Promise<void> =>
  request({
    url: `/admin/users/${id}`,
    method: 'delete',
  }).then((o) => o.data);
