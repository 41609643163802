<template><div /></template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * This file should be left completely blank in our platform code.
 * It will only be populated in a whitelabel branch to completely
 * replace the PDF footer on each page
 */
export default defineComponent({
  name: 'WhitelabelPdfFooter',
});
</script>
