<template>
  <b-container>
    <b-row
      ref="glossaryHeader"
      class="sticky-top bg-white no-gutters border-lighter-gray border-bottom-2x py-2"
      :style="{ top: `${applicationNavbarHeight}px` }"
    >
      <b-col>
        <b-row class="pb-3">
          <b-col
            col
            class="ml-3 pr-0"
          >
            <div>
              <TypeaheadSearchBox
                :data-to-search="searchCandidate"
                key-property="title"
                :query.sync="searchBoxQuery"
                placeholder="Search for a term or tool (e.g., p-value, Factor Regression)"
                :serializer="searchSerializer"
                :disabled-values="disabledItems"
                :add-to-selected-items="false"
                @onHit="onHit"
              >
                <template #suggestion="{ data }">
                  <span
                    :class="
                      data.type === 'Definition'
                        ? 'text-primary d-flex justify-content-between'
                        : shownCategories.includes(data.title)
                        ? 'text-light-gray'
                        : 'text-info'
                    "
                  >
                    {{ data.type }}: {{ data.title }}
                    <!-- Add small badges for the data's categories -->
                    <span>
                      <span
                        v-for="category of data.categories"
                        :key="category"
                        class="badge badge-info font-weight-normal mt-1 ml-1 py-1 px-2"
                      >
                        {{ category }}
                      </span>
                    </span>
                  </span>
                </template>
              </TypeaheadSearchBox>
            </div>
          </b-col>
          <b-col cols="auto">
            <div class="d-flex align-items-center h-100">
              <b-button
                v-for="category of shownCategories"
                :key="`${category}`"
                class="mr-3"
                size="sm"
                variant="info"
                @click="removeCategory(category)"
              >
                {{ category }}
                <icon
                  icon="remove"
                  class="ml-1"
                />
              </b-button>
              <b-button
                v-if="shownCategories.length > 1"
                size="sm"
                variant="danger"
                @click="shownCategories = []"
              >
                <icon icon="trash-alt" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-nav
              v-if="!isKnowledgeBaseSidebar"
              v-b-scrollspy="{
                element: 'body',
                offset: scrollMargin,
              }"
              class="d-flex justify-content-between"
              pills
            >
              <b-nav-item
                v-for="group in groupedGlossaries"
                :key="group.letter"
                class="border-0 text-center font-weight-bold py-1 smaller-a-padding"
                :class="group.disabled ? 'cursor-not-allowed' : ''"
                :disabled="group.disabled"
                :to="group.to"
              >
                {{ group.label }}
              </b-nav-item>
            </b-nav>
            <b-nav
              v-else
              class="d-flex justify-content-between"
              pills
            >
              <b-nav-item
                v-for="group in groupedGlossaries"
                :key="group.letter"
                class="border-0 text-center font-weight-bold py-1 smaller-a-padding"
                :class="group.disabled ? 'cursor-not-allowed' : ''"
                :disabled="group.disabled"
                @click="goToGlossaryTarget(group.domId)"
              >
                {{ group.label }}
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          bg-variant="off-white"
          class="px-5 border-0"
        >
          <dl>
            <template v-for="group in groupedGlossaries">
              <!--
                Noted that the scroll-margin-top should be placed
                on the element with the ID attribute (the scroll target)
              -->
              <div
                v-show="!group.disabled"
                :id="group.domId"
                :key="group.letter"
                class="collapse-transition-item"
                :style="{ 'scroll-margin-top': `${scrollMargin}px` }"
              >
                <b-row>
                  <b-col>
                    <h4 class="position-relative text-primary text-center my-4">
                      {{ group.label }}
                    </h4>
                  </b-col>
                </b-row>
                <div
                  v-for="term of group.content"
                  v-show="term.shown"
                  :key="term.id"
                >
                  <div>
                    <GlossaryItem
                      :id="term.id"
                      :component="term.component"
                      :title="term.title"
                      :categories="term.categories"
                      :hidden-categories="term.hiddenCategories"
                      :related="term.related"
                      :shown-categories="shownCategories"
                      :glossary-map="glossaryMap"
                      @toggle-category="toggleCategory"
                    />
                    <hr :key="`${term.id}-divider`" />
                  </div>
                </div>
              </div>
            </template>
          </dl>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, Ref } from 'vue';
import TypeaheadSearchBox from '@/components/TypeaheadSearchBox.vue';
import { GlossarySearchItem, GlossaryCategory, SEARCH_TERM_TYPE } from '@/types/glossary';
import { ACCEPTED_TRACKED_TITLES } from '@/types/analytics';
import { VBScrollspy } from 'bootstrap-vue';
import GlossaryItem from './glossary/GlossaryItem.vue';
import { GlossaryFrontmatter, parseGlossaryFrontmatter } from './glossary/GlossaryFrontmatter';
import { groupByMap } from '@/utils/groupByMap';
import { useRouter } from '@/composables/useRouter';
import useAppMetrics, { useTrackPageView } from '@/composables/useAppMetrics';
import { useToasts } from '@/composables/useToasts';
import { useElementSize } from '@vueuse/core';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { useScrollToUrlHash } from '@/composables/useScrollToUrlHash';
import { ApplicationNavbarHeight } from '@/constants/ApplicationNavbarHeight';
import { KnowledgeBaseMode } from '@/types/KnowledgeBase';
import useEnv from '@/composables/useEnv';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

const MAX_NUM_CATEGORIES = 4;
const pageName = 'Resources';
const subpageName = 'Glossary';

function encodeChar(char: string): string {
  const notLetter = /[^a-zA-Z]/;

  if (char.match(notLetter)) {
    return 'symbol';
  }

  return char;
}

const BOFA_CATEGORY_ITEMS: GlossarySearchItem[] = [
  {
    title: GlossaryCategory.PERFORMANCE_METRICS,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.STRATEGY_FILTER,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  // Portfolio Construction
  {
    title: GlossaryCategory.PORTFOLIO_ANALYSIS,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_REBALANCING,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_VALUE,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.CUSTOM_REBALANCING,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_CONSTRUCTION,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.FX_CONVERSION,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
];

const alwaysAvailableCategoryItems: GlossarySearchItem[] = [
  // strategy Universe
  {
    title: GlossaryCategory.PERFORMANCE_METRICS,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.STRATEGY_FILTER,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  // Portfolio Construction
  {
    title: GlossaryCategory.PORTFOLIO_ANALYSIS,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_REBALANCING,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_VALUE,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.CUSTOM_REBALANCING,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.PORTFOLIO_CONSTRUCTION,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
  {
    title: GlossaryCategory.FX_CONVERSION,
    type: SEARCH_TERM_TYPE.CATEGORY,
  },
];

function useCategories() {
  const { canSeeRegressionGlossary, canSeePcaGlossary, canSeeConstituentGlossary } = useFeatureFlag();
  const { isBofAEnvironment } = useEnv();

  const categories = computed(() => {
    const ret = isBofAEnvironment ? [...BOFA_CATEGORY_ITEMS] : [...alwaysAvailableCategoryItems];

    if (canSeePcaGlossary.value) {
      ret.push(
        {
          title: GlossaryCategory.PCA,
          type: SEARCH_TERM_TYPE.CATEGORY,
        },
        {
          title: GlossaryCategory.PCA_FULL,
          type: SEARCH_TERM_TYPE.CATEGORY,
        },
      );
    }

    if (canSeeRegressionGlossary.value) {
      ret.push({
        title: GlossaryCategory.FACTOR_REGRESSION,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.FACTOR_DEFINITION,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.ANALYTICS_COMPUTATION,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
    }

    if (canSeeConstituentGlossary.value) {
      ret.push({
        title: GlossaryCategory.EX_ANTE,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.DURATION_CONVEXITY,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.VALUE_AT_RISK,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.GREEKS,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
    }

    // BofA specific categories, always exposed
    if (isBofAEnvironment) {
      ret.push({
        title: GlossaryCategory.FACTOR_DEFINITION,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.ANALYTICS_COMPUTATION,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.DURATION_CONVEXITY,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.VALUE_AT_RISK,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
      ret.push({
        title: GlossaryCategory.GREEKS,
        type: SEARCH_TERM_TYPE.CATEGORY,
      });
    }

    ret.sort((a, b) => a.title.localeCompare(b.title));

    return ret;
  });

  const categoriesSet = computed(() => new Set(categories.value.map((cat) => cat.title)));

  return {
    categories,
    categoriesSet,
  };
}

function useGlossaries(categoriesSet: Ref<Set<string>>, shownCategoriesSet: Ref<Set<GlossaryCategory>>) {
  const { shouldShowUnknownTermsGlossary } = useFeatureFlag();
  /**
   * List of all (raw) glossary items.
   *
   * You should be using `glossaries` variable instead.
   */
  const rawGlossaries = computed((): GlossaryFrontmatter[] => {
    const files = import.meta.glob('./glossary/items/**/*.md', { eager: true });

    return Object.values(files)
      .map((file) => parseGlossaryFrontmatter(file))
      .sort((a, b) => a.title.localeCompare(b.title));
  });

  /**
   * Map for glossary id -> glossary item.
   *
   * This is mostly for the "related" term inside glossary.
   */
  const glossaryMap = computed(() => {
    return new Map(rawGlossaries.value.map((term) => [term.id, term]));
  });

  /**
   * List of glossaries, after filtered by user permission.
   */
  const glossaries = computed(() =>
    rawGlossaries.value
      .filter((term) => {
        // don't show Pure Factors on whitelabel platforms
        if (
          import.meta.env.VITE_CLIENT &&
          import.meta.env.VITE_CLIENT.toString() !== 'Premialab' &&
          term.id === 'pure-factors'
        ) {
          return false;
        }

        // Do not show the term if it is in some of the unknown categories
        if (shouldShowUnknownTermsGlossary.value)
          for (const category of term.allCategories) {
            if (!categoriesSet.value.has(category)) {
              return false;
            }
          }

        return true;
      })
      .map((term) => ({
        ...term,
        shown:
          shownCategoriesSet.value.size === 0 || term.allCategories.some((cat) => shownCategoriesSet.value.has(cat)),
      })),
  );

  const groupedGlossaries = computed(() => {
    const groups = groupByMap(glossaries.value, (term) => encodeChar(term.title[0].toUpperCase()));

    return '%ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((label) => {
      const letter = label === '%' ? 'symbol' : label;
      const content = groups.get(letter) ?? [];
      const domId = `glossary-${letter}-items`;

      return {
        letter,
        label,
        content,
        // This will also be true if the array is empty.
        disabled: !content.some((term) => term.shown),
        domId,
        to: { hash: `#${domId}` },
      };
    });
  });

  return {
    glossaryMap,
    glossaries,
    groupedGlossaries,
  };
}

function useCategoryFilter() {
  const { track } = useAppMetrics();

  /**
   * List of shown categories by filter
   */
  const shownCategories = ref<Array<GlossaryCategory>>([]);
  const shownCategoriesSet = computed(() => new Set(shownCategories.value));

  const removeCategory = (category: GlossaryCategory) => {
    shownCategories.value = shownCategories.value.filter((item) => item !== category);
  };

  const toggleCategory = (category: GlossaryCategory) => {
    if (shownCategoriesSet.value.has(category)) {
      removeCategory(category);
    } else {
      shownCategories.value = [...shownCategories.value, category];
    }
  };

  watch(shownCategories, () => {
    // if this scroll event happens too quickly, it either does not scroll at all, or
    // it scrolls to the top of the page. Both are unacceptable. thus the setTimeout
    // nextTick did not seem to be enough
    setTimeout(() => {
      window.scrollTo({ top: 400, behavior: 'smooth' });
    });

    track(ACCEPTED_TRACKED_TITLES.GLOSSARY_MODIFIED, {
      pageName,
      subpageName,
      modificationType: 'Filter Applied',
      filterCategory: shownCategories.value[shownCategories.value.length - 1],
    });
  });

  return {
    shownCategories,
    shownCategoriesSet,
    removeCategory,
    toggleCategory,
  };
}

export default defineComponent({
  name: 'GlossaryPage',
  components: {
    TypeaheadSearchBox,
    GlossaryItem,
  },
  directives: {
    'b-scrollspy': VBScrollspy,
  },
  setup() {
    const { errorToast } = useToasts();

    const router = useRouter();

    const { track } = useAppMetrics();

    useTrackPageView({ pageName, subpageName });

    const glossaryHeader = ref<HTMLElement | null>(null);

    const { height: glossaryHeaderHeight } = useElementSize(glossaryHeader);

    const { scrollMargin } = useScrollToUrlHash();

    watch(
      () => ApplicationNavbarHeight + glossaryHeaderHeight.value,
      (val) => (scrollMargin.value = val),
    );

    const { mode } = useKnowledgeBase();

    const isKnowledgeBaseSidebar = computed(() => mode.value === KnowledgeBaseMode.SIDEBAR);

    const { categories, categoriesSet } = useCategories();
    const { shownCategories, shownCategoriesSet, removeCategory, toggleCategory } = useCategoryFilter();
    const { glossaries, glossaryMap, groupedGlossaries } = useGlossaries(categoriesSet, shownCategoriesSet);

    const searchBoxQuery = ref('');
    /**
     * The search result is an array of GlossarySearchItem, which is the union type of Category and Term.
     * If the search box query is empty, then all the categories will be returned.
     * If the search box query is not empty, then we will filter the glossaries based on the categories that are shown.
     */
    const searchCandidate = computed((): GlossarySearchItem[] => {
      if (searchBoxQuery.value === '') {
        return categories.value;
      }

      const shownCategorySet = new Set(shownCategories.value);

      /**
       * Returns glossaries with categories in shownCategorySet to be with type 'DEFINITION'
       */
      const ret = glossaries.value
        .filter((term) => shownCategorySet.size == 0 || term.categories.some((cat) => shownCategorySet.has(cat)))
        .map(
          (term): GlossarySearchItem => ({
            title: term.title,
            type: SEARCH_TERM_TYPE.DEFINITION,
            categories: term.categories,
            id: term.id,
          }),
        );

      return [...ret, ...categories.value];
    });

    /**
     * Returns an array of objects to be disabled whose type is 'category' and
     * whose title is in the array of strings.
     */
    const disabledItems = computed(() => {
      return searchCandidate.value.filter(
        (item) => item.type === SEARCH_TERM_TYPE.CATEGORY && shownCategories.value.includes(item.title),
      );
    });

    const searchSerializer = (item: GlossarySearchItem) => item.title;

    const onHit = (item: GlossarySearchItem) => {
      searchBoxQuery.value = '';

      if (item.type === SEARCH_TERM_TYPE.CATEGORY) {
        if (shownCategories.value.length >= MAX_NUM_CATEGORIES) {
          errorToast("You've reached the max number of categories");
          return;
        }

        // Add the searched category to the list of shown categories
        if (!shownCategoriesSet.value.has(item.title)) {
          shownCategories.value = [...shownCategories.value, item.title];
        }
      }

      if (item.type === SEARCH_TERM_TYPE.DEFINITION) {
        if (mode.value === KnowledgeBaseMode.PAGE) {
          router.push({ hash: item.id });
        } else {
          // If knowledge base mode is sidebar, then scroll directly without pushing hash in router
          goToGlossaryTarget(item.id);
        }
        track(ACCEPTED_TRACKED_TITLES.GLOSSARY_MODIFIED, {
          pageName,
          subpageName,
          modificationType: 'Definition Selected',
          definitionName: item.title,
          definitionCategories: item.categories,
        });
      }
    };

    // Scrolls down for targeted glossaries in knowledge base sidebar
    const goToGlossaryTarget = (id: string) => {
      document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      glossaryHeader,
      glossaryMap,
      groupedGlossaries,

      shownCategories,
      removeCategory,
      toggleCategory,

      searchBoxQuery,
      searchCandidate,
      searchSerializer,
      disabledItems,
      onHit,

      scrollMargin,

      applicationNavbarHeight: ApplicationNavbarHeight,

      isKnowledgeBaseSidebar,

      goToGlossaryTarget,
    };
  },
});
</script>

<style scoped>
.nav-link.disabled {
  color: lightgray;
  pointer-events: none;
}
.smaller-a-padding > a {
  padding: 0.5rem 0.85rem;
}
</style>
